import { ref, reactive, onMounted, computed } from 'vue';
import { isArray } from 'lodash-es';
import { useModal } from './useModal';

export function useCRUD(config = {}) {
  const { createModal } = useModal({ onReload: config.onReload || onFetch });
  const fetch =
    config.fetch ||
    (() => {
      throw 'Fetch MUST BE function';
    });
  const remove =
    config.remove ||
    (() => {
      throw 'Remove MUST BE function';
    });

  const query = reactive(config.query ?? {});
  const result = ref(config.result ?? []); // ajax response 的内容
  const records = ref(config.records ?? []); // ajax response 的内容中的数组部分, 分页是在content下, 不分页时返回的就直接是数组
  const isEmpty = computed(() => records.value.length === 0);

  const ModalAdd = config.ModalAdd || config.Modal;
  function onAdd(model = {}, options = {}) {
    return createModal(ModalAdd, { action: 'add', ...options, model });
  }

  const ModalEdit = config.ModalEdit || config.Modal;
  function onEdit(model = {}, options = {}) {
    return createModal(ModalEdit, { action: 'edit', ...options, model });
  }

  const ModalDetail = config.ModalDetail || config.Modal;
  function onDetail(model = {}, options = {}) {
    return createModal(ModalDetail, { action: 'detail', ...options, model });
  }

  const loading = ref(false);
  function onFetch(params, handlePagination) {
    loading.value = true;
    return fetch({ ...query, ...params })
      .then(data => {
        result.value = data;
        records.value = isArray(data) ? data : data?.content || [];
        handlePagination && handlePagination(data);
        config.onSuccess && config.onSuccess(data);
        return data;
      })
      .finally(() => {
        loading.value = false;
      });
  }

  function onRemove(id, reload = true) {
    loading.value = true;
    return remove(id)
      .then(data => {
        return reload ? onFetch() : data;
      })
      .finally(() => {
        loading.value = false;
      });
  }

  /**
   * @Author Ken
   * @CreateDate 2021-12-13 18:05
   * @desc update record
   * @params
   * action: must be Function
   * reload: true: reload after action
   * @return
   */
  async function sendAction(action, reload = true) {
    let ret = null;
    try {
      loading.value = true;
      ret = await action();
    } finally {
      loading.value = false;
    }
    reload && (await onFetch());
    return ret;
  }

  onMounted(() => {
    const { preload = true } = config;
    if (preload) {
      return onFetch();
    }
  });

  return {
    query,
    loading,
    result,
    records,
    isEmpty,
    fetch,
    remove,
    ModalAdd,
    ModalEdit,
    ModalDetail,
    onAdd,
    onEdit,
    onDetail,
    createModal,
    onFetch,
    onRemove,
    sendAction,
  };
}
